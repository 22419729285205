<template>
  <div>
    <div id="first">
      <progress-ring
        :radius="90"
        :stroke="12"
        :max="100"
        :digits="false"
        :progress="progress"
        :color="'#ffcd32'"
      />
    </div>
    <div id="second">
      <progress-ring
        :radius="63"
        :stroke="8"
        :max="100"
        :digits="true"
        :progress="progress"
        :color="'#ffdd6b'"
      />
    </div>
  </div>
</template>

<script>
import 'static/css/style.css'
import ProgressRing from '@/components/ProgressRing'
export default {
  components: {
    ProgressRing
  },
  props: {
    progress: { type: Number, default: 50 }
  },
  data() {
    return {
      interval: null,
      digitsVisible: true,
      customColor: 'orange'
    }
  },
  watch: {
    progress(val) {
      document.body.style.background = `hsl()`
    }
  },
  mounted() {
    this.setProgress(this.progress)
  },
  methods: {
    setProgress(value) {
      this.progress = 0
      clearInterval(this.interval)
      // emulating progress
      this.interval = setInterval(() => {
        this.progress += 1
        if (this.progress >= value) {
          this.progress = value
          clearInterval(this.interval)
        }
      }, 50)
    },
    setColor(value) {
      this.customColor = value
    }
  }
}
</script>
<style scoped>
#first {
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(-30deg);
}
#second {
  position: absolute;
  top: 29px;
  left: 28px;
}
</style>
