<template>
  <loading
    :active.sync="isLoading"
    :can-cancel="false"
    :is-full-page="true"
    color="#007bff"
    :height="100"
    :width="100"
    loader="dots"
    background-color="#fff"
    :opacity="1"
  >
    <div class="wrapper">
      <div class="spider">
        <div class="chain first-chains very-first-chain">
          <div class="spiderlogo" />
        </div>
      </div>
    </div>
    <b-row class="justify-content-center align-items-center text-center m-0">
      <h4>Crawling website... {{ hostname }}</h4>
      <div class="mb-1 px-1">
        <b-spinner
          v-for="variant in variants"
          :key="variant"
          small
          align-self="center"
          :variant="variant"
          type="grow"
        />
      </div>
    </b-row>
  </loading>
</template>

<script>
import 'static/css/loading_style.css'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
export default {
  components: {
    Loading
  },
  props: {
    hostname: { type: String, default: '' },
    isLoading: { type: Boolean, default: false },
    variants: { type: Array, default: null }
  }
}
</script>
<style scoped>
.vld-overlay {
  align-items: baseline;
}
</style>
