<template>
  <b-container fluid class="pt-5">
    <b-alert
      class="text-center"
      variant="danger"
      dismissible
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
    >
      {{ isError }}
    </b-alert>
    <b-row class="justify-content-md-center align-items-center">
      <b-col
        lg="5"
        md="5"
        sm="10"
        xs="10"
        class="left-text"
        data-scroll-reveal="enter left move 30px over 0.6s after 0.4s"
      >
        <h1>
          <strong>A perfect auditor for your website</strong>
        </h1>

        <b-form test="b-form" class="w-100 mt-4" @submit.prevent="format">
          <b-input-group class="hostname-input">
            <b-form-input
              id="input-1"
              v-model.trim="website.hostname"
              v-lowercase
              type="text"
              :required="true"
              class="w-75 text-center"
              pattern="^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-zA-Z0-9]+([\-\.]{1}[a-zA-Z0-9]+)*\.[a-zA-Z]{2,12}?(\/.*)?$"
              placeholder="https://www.example.com"
            />
            <b-input-group-append>
              <b-button id="addSite" test="add-website" type="submit">
                <b-icon-caret-right-fill />
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form>
        <p class="text-justify">
          Websites are powerful branding identities for contemporary businesses.
          They are the core hub for new leads, fresh content, loyal customers,
          and campaigns that serve as a vehicle for your marketing tactics.
        </p>
        <p class="text-justify">
          With this web auditor in place, we ensure that websites continue to
          perform well and yield desirable results, making it sensible for your
          digital footprint. Our handy tool examines various attributes of the
          website or online store in terms of their effectiveness and
          efficiency. It identifies inconsistencies, bugs, missing links, and
          broken pages that could impede your site users’ browsing journey.
        </p>
      </b-col>
      <b-col
        lg="6"
        md="6"
        sm="10"
        xs="10"
        class="displayNone"
        data-scroll-reveal="enter right move 30px over 0.6s after 0.4s"
      >
        <b-img
          src="../static/images/home.png"
          class="rounded img-fluid d-block mx-auto"
          alt="First Vector Graphic"
        />
      </b-col>
    </b-row>
    <!-- <b-row class="dnmd">
      <b-col lg="4" md="4" sm="10" xs="10">
        <b-img
          src="../static/images/homeStatistics.png"
          class="rounded img-fluid d-block mx-auto"
          alt="First Vector Graphic"
        />
      </b-col>
      <b-col lg="4" md="4" sm="10" xs="10">
        <b-img
          src="../static/images/homeRocket.png"
          class="rounded img-fluid d-block mx-auto"
          alt="First Vector Graphic"
        />
      </b-col>
    </b-row> -->
    <loading
      :is-loading="isLoading"
      :variants="variants"
      :hostname="website.hostname"
    />
    <b-row
      class="example-bg-section justify-content-md-center align-items-center"
    >
      <b-container>
        <h2 class="text-center example-heading">
          <strong>Examples of our WebAudits</strong>
        </h2>
        <div v-if="siteShowCaseData" class="showcase-container">
          <div v-for="(site, index) in siteShowCaseData" :key="index">
            <a :href="site.hostname" class="card-style" target="_blank">
              <b-card class="card-style-hover d-flex justify-content-between">
                <b-card-title class="my-2 mr-4 text-truncate">
                  <b-avatar
                    class="favicon"
                    :src="site.favicon === '' ? defaultFavicon : site.favicon"
                  />
                  {{ site.hostname }}
                </b-card-title>
                <div id="showcase-sitescore">
                  <Scorecard :progress="site.score" />
                </div>
              </b-card>
            </a>
          </div>
        </div>
      </b-container>
    </b-row>
  </b-container>
</template>

<script>
import '@/static/css/app.css'
import Loading from '@/components/loading'
import Scorecard from '@/components/Scorecard'
export default {
  layout: 'seo',
  name: 'WebAuditor',

  components: {
    Loading,
    Scorecard
  },
  directives: {
    lowercase: {
      update(el) {
        el.value = el.value.toLowerCase()
      }
    }
  },
  async asyncData({ store, params, redirect }) {
    await store.dispatch('getSiteShowCaseData')
  },

  data() {
    return {
      variants: [
        'primary',
        'secondary',
        'danger',
        'warning',
        'success',
        'info',
        'light',
        'dark'
      ],
      website: {
        hostname: null
      },

      showDismissibleAlert: false,
      errorMsg: null
    }
  },
  computed: {
    isLoading() {
      return this.$store.getters.getLoading
    },
    isError() {
      return this.$store.getters.getErrorMsg
    },
    siteShowCaseData() {
      return this.$store.getters.siteShowCaseData
    }
  },
  mounted() {
    this.$nextTick(function() {
      console.log('Add website mounted ' + Date())
    })
    if (this.$store.state.errMsg) {
      this.showDismissibleAlert = true
    }
  },
  methods: {
    format() {
      const check = this.website.hostname
      if (check.includes('https://') || check.includes('http://')) {
        const format = document.createElement('a')
        format.href = this.website.hostname
        this.website.hostname = format.hostname
        this.addWebsite()
      } else {
        this.addWebsite()
      }
    },
    async addWebsite() {
      await this.$store.dispatch('addWebsite', this.website)
      if (this.$store.state.errMsg) {
        this.showDismissibleAlert = true
      }
      if (this.$store.state.loading) {
        await this.$store.dispatch('checkSiteStatus', this.website)
        this.$store.commit('setLoading', false)
        if (this.$store.state.errMsg) {
          this.showDismissibleAlert = true
        }
      }
    }
  },
  head() {
    return {
      title: 'WebAuditor'
    }
  }
}
</script>

<style>
#input-1 {
  background-color: #fff;
  border: solid #fff;
  text-transform: lowercase;
}
html {
  width: 100%;
  height: 100%;
}
body {
  width: 100%;
  height: 100%;
}
p {
  margin-top: 3rem;
  margin-bottom: 0rem;
}
#addSite {
  color: #212529;
  background-color: #fab414;
  border-color: #fab414;
}
#addSite:hover {
  color: #fff;
}
.input-group {
  border: solid #343a40 2px;
  border-radius: 20px;
  overflow: hidden;
}
.hostname-input input {
  text-transform: lowercase;
}
.example-bg-section {
  background-color: #f9f9f9 !important;
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.example-heading {
  padding-bottom: 3rem;
}

a.card-style {
  color: black;
  text-decoration: none;
}
.card-style-hover {
  border: 2px solid transparent;
}
.card-style-hover:hover {
  border: 2px solid #fab414;
}

.card-col {
  padding-bottom: 10px;
}

.card-style-custom:hover {
  border: 2px solid #fab414;
}
.card-style-custom {
  border: 2px solid transparent;
}

.card-title {
  margin-bottom: 0.25rem !important;
  margin-top: 0.25rem !important;
  color: #343a40;
}
.b-avatar {
  width: 30px;
  height: 30px;
  border-radius: 2px !important;
  border: 1px solid transparent;
  background-color: transparent;
}
.card-body {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.25rem 1.25rem;
}
.showcase-container {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 0.5rem 1.25rem;
}
#showcase-sitescore {
  width: 40px;
  height: 40px;
  aspect-ratio: 1/1;
  transform: translate(-120%, -86.5%) scale(0.45);
}
@media only screen and (max-width: 999px) {
  .showcase-container {
    grid-template-columns: 100%;
  }
  .example-bg-section {
    padding-bottom: 2rem;
  }
}
@media only screen and (max-width: 576px) {
  .b-avatar {
    width: 24px;
    height: 24px;
  }
  .card-title {
    font-size: 1.2rem;
  }
  .card-body {
    padding: 1rem 0.75rem;
    border: 2px solid transparent;
  }
  #showcase-sitescore {
    transform: translate(-90%, -73%) scale(0.4);
  }
  .example-bg-section {
    padding-bottom: 1rem;
  }
}
</style>
